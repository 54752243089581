import React, { useState } from "react"
import Hero from "../components/Hero";

const Reserveren = () => {
    const [beursFormData, setBeursFormData] = useState({
        email: "",
        naam: "",
        organisatie: "",
        standplaats: "",
        standnummer: ""
    });

    const [dataSent, setDataSent] = useState(false);

    const handleInputChange = (e) => {
        console.log("open change");
        const { name, value} = e.target;
        setBeursFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(beursFormData);
        fetch("https://geobuzz.nl/api/beursplaats", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(beursFormData)
          }).then((response) => {
            console.log("fetch call gedaan");
            setBeursFormData({
                email: "",
                naam: "",
                organisatie: "",
                standplaats: "",
                standnummer: ""
            });
            console.log(response);
            if (response.ok) {
                setDataSent(true)
            };
        });
    }
    
    return (
        <div className="reserveren__container">
            <Hero heroTitle="Beursplaats reserveren"/>
            <h2 className="reserveren__header">De beurs is vol</h2>
            <p className="reserveren__form">Het is niet meer mogelijk om een beursplaats te reserveren. Alvast een beursplaats voor 2025 reserveren? Neem contact met ons op en we plaatsen u op de wachtlijst.</p>
            {/*
            <div className="reserveren__form">
                <form onSubmit={handleSubmit}>
                    <label for="email">E-mailadres</label>
                    <input
                        type="text"
                        name="email"
                        id="email"
                        value={beursFormData.email}
                        onChange={handleInputChange}
                    />
                    <label for="voornaam">Naam</label>
                    <input
                        type="text"
                        name="naam"
                        id="naam"
                        value={beursFormData.naam}
                        onChange={handleInputChange}
                    />
                    <label for="organisatie">Organisatie</label>
                    <input
                        type="text"
                        name="organisatie"
                        id="organisatie"
                        value={beursFormData.organisatie}
                        onChange={handleInputChange}
                    />
                    <label for="standplaats">Welke standplaats wenst u?</label>
                        <select name="standplaats" value={beursFormData.standplaats} onChange={handleInputChange}>
                            <option value="standplaats">Standplaats</option>
                            <option value="bannerstand">Bannerstand</option>
                            <option value="praattafel">Praattafel</option>
                            <option value="datainwin12">Data inwinplein 12m2</option>
                            <option value="datainwin16">Data inwinplein 16m2</option>
                        </select>
                    <label for="standnummer">Voorkeur standnummer</label>
                    <input
                        type="text"
                        name="standnummer"
                        id="standnummer"
                        value={beursFormData.standnummer}
                        onChange={handleInputChange}
                    />
                    <input type="submit" />
                </form>
                {
                    dataSent && (<p>Bedankt voor de reservering. We nemen zo snel mogelijk contact met u op</p>)
                }
            </div>
             */}
        </div>
    )
}

export default Reserveren;
import React from "react";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

// import logo from './logo.svg';
// Componenten
import Root from "./components/Root";
import Home from "./pages/Home";
import Beurs from "./pages/Beurs";
import Plattegrond from "./pages/Plattegrond";
import Prijzen from "./pages/Prijzen";
import Reserveren from "./pages/Reserveren";
import Programma from "./pages/Programma";
import Deelname from "./pages/Deelname";
import Contact from "./pages/Contact";
import PresentationSingle from "./pages/PresentationSingle";
import Handboek from "./pages/Handboek";
import Winkel from "./pages/Winkel";
import Streamtheater from "./pages/Streamtheater"

//data
import WinkelData from "./data/winkel-producten.json";
import data from "./data/presentations-25-11.json";
import CateringData from "./data/catering.json";

// fetch data helper
// import getPresentationData from "./helpers/FetchPresentationData";
// const data = getPresentationData();
// console.log(data);

const router = createBrowserRouter(createRoutesFromElements(
  <Route path="/" element={<Root />}>
    <Route index element={<Home />} />
    <Route path="beurs" element={<Beurs />}>
      <Route path="plattegrond" element={<Plattegrond />} />
    </Route>
    <Route path="programma" element={<Programma data={data} />} />
    <Route path="/programma/:id" element={<PresentationSingle data={data} />} />
    <Route path="/streamtheater" element={<Streamtheater/>}/>
    <Route path="deelname" element={<Deelname />} />
    <Route path="contact" element={<Contact />} />
    <Route path="beurs-handboek" element={<Handboek />} />
    <Route path="prijzen" element={<Prijzen />} />
    <Route path="winkel" element={<Winkel data={WinkelData} cateringData={CateringData}/>} />
    <Route path="reserveren" element={<Reserveren />} />
  </Route>
));

function App() {
  return (
    <RouterProvider router={router}/>
  );
}

export default App;

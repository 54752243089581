import React from "react";

const WinkelCart = ({ cart, updateQuantity }) => {
    
    return (
        <div className="winkel__cart__order">
            {cart.map(product => {
                return (
                    <ul className="winkel__cart__product">
                        <li key={product.id}>
                            <div className="winkel__cart__quantity">
                            <button onClick={() => updateQuantity(product, -1)}>-</button>
                            <span>{product.quantity}</span>
                            <button onClick={() => updateQuantity(product, 1)}>+</button>
                            </div>
                            {product.product}
                        </li>
                    </ul>
                )
            })}
        </div>
    )
}

export default WinkelCart;
import React from "react";

const CateringFormulierOverlay = ({ isVisible, onClose, productData, addToCart }) => {
    const cateringProducten = productData.filter(product => product.type === "catering");

    if (!isVisible) return null;

    const handleBackgroundClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };


    return (
        <div className="winkel__overlay" onClick={handleBackgroundClick}>
            <div className="winkel__overlay-content">
                <h3>Catering lijst</h3>
                <button className="winkel__overlay-close-button" onClick={onClose}>Sluiten</button>
                <ul>
                    {cateringProducten.map(product => {
                        return (
                            <li key={product.id} className="winkel__overlay__product">
                                <p>{product.product} - € {product.prijs}</p>
                                <button onClick={() => addToCart(product, 1)}>Toevoegen</button>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    );
};

export default CateringFormulierOverlay;
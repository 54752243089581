import React, { useState } from "react";
import imagesWinkel from "../assets/imagesWinkel";

const WinkelCard = ({ productObject, addToCart, buttonText }) => {
    const [quantity, setQuantity] = useState(0);

    const addQuantity = () => {
        const newQuantity = quantity + 1;
        setQuantity(newQuantity);
    }

    const decreaseQuantity = () => {
        if (quantity > 0) {
            const newQuantity = quantity - 1;
            setQuantity(newQuantity);
        }
    }

    return (
        <div className="winkelCard">
            <div className="winkelCard__preview">
                {productObject.prijs && <p className="winkelCard__prijs">€ {productObject.prijs}</p>}
                <img src={imagesWinkel[productObject.afbeelding]} />
            </div>
            <div className="winkelCard__content">
                <h3>{productObject.product}</h3>
                <p className="winkelCard__location">{productObject.beschrijving}</p>
                <div className="winkelCard__bottom">
                    {productObject.id !== "18" ?
                        <>
                            <div className="winkelCard__bottom__quantity">
                                <button onClick={decreaseQuantity}>-</button>
                                <p>{quantity}</p>
                                <button onClick={addQuantity}>+</button>
                            </div>
                            <button className="winkelCard__link" onClick={() => addToCart(productObject, quantity)}>{buttonText}</button>
                        </>
                        :
                        <button className="winkelCard__link" onClick={() => addToCart(productObject)}>{buttonText}</button>
                    }
                </div>
            </div>
        </div>
    )
}

export default WinkelCard;